import { UserDto } from './index';

export class User {
    id: string;
    email: string;
    accountId: number;
    name: string;

    constructor(userDto: UserDto) {
        this.id = userDto.id;
        this.email = userDto.email;
        this.accountId = userDto.account_id;
        this.name = userDto.name;
    }
}
